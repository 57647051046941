export const wordList = [
    "World",
    "School",
    "State",
    "Student",
    "Group",
    "Country",
    "Place",
    "Company",
    "System",
    "Program",
    "Question",
    "Number",
    "Server",
    "Code",
    "Algorithm",
    "Programming",
    "Internet",
    "Website",
    "WebApplication",
    "Firewall",
    "Router",
    "CyberSecurity",
    "Cloud",
    "Storage",
    "Backup",
    "Business",
    "Service",
    "Power",
    "Member",
    "Community",
    "Closed",
    "Plane",
    "Monitor",
    "Reconnect",
    "Switch",
    "Command",
    "Table",
    "Computer",
    "Software",
    "Hardware",
    "Database",
    "Network",
    "OperatingSystem",
    "Browser",
    "Encryption",
    "MobileDevice",
    "ApplicationProgrammingInterface",
    "Protocol",
    "Password",
    "Authentication",
    "Firewall",
    "InternetOfThings",
    "Virtualization",
    "JavaScript",
    "ServerFarm",
    "DataCenter",
    "UserInterface",
    "Azure",
    "AmazonWebServices",
    "Microsoft",
    "EnterpriseResourcePlanning",
    "TypeScript",
    "Csharp",
    "DataMining",
    "ArtificialIntelligence",
    "MachineLearning",
    "Robotics",
    "AugmentedReality",
    "VirtualReality",
    "SoftwareDevelopment",
    "Source",
    "Support",
    "User",
    "Keyboard",
    "Join",
    "Portal",
    "Moon",
    "Star",
    "Airplane",
    "Plane",
    "Lights",
    "Save",
    "Terminal",
    "Upgrade",
    "Install",
    "Settings",
    "Tips",
    "Paint",
    "Notepad",
    "Paper",
    "Monitor",
    "Remote",
    "Desktop",
    "Connection",
    "Connect",
    "File",
    "Home",
    "Data",
    "Action",
    "Save",
    "Keys",
    "Send",
    "Command",
    "Family",
    "Teams",
    "Excel",
    "Edge",
    "Outlook",
    "Flower",
    "Photo",
    "Template",
    "Reconnect",
    "People",
    "Chat",
    "Camera",
    "Share",
    "Window",
    "Door",
    "Wall",
    "Roof",
    "Sofa",
    "Table",
    "Chair",
    "More",
    "Script",
    "Password",
    "Title",
    "Azure",
    "Automation",
    "Cloud",
    "Information",
    "Tech",
    "School",
    "Picture",
    "Movie",
    "Film",
    "Watch",
    "Show",
    "Tree",
    "Button",
    "Network",
    "Cable",
    "Find",
    "Change",
    "Dashboard",
    "Board",
    "Dash",
    "Exact",
    "Simple",
    "Word",
    "Hello",
    "World",
    "Building",
    "Office",
    "Guest",
    "Welcome",
    "Random",
    "Wizard",
    "House",
    "Road",
    "Cars",
    "Bike",
    "Boat",
    "Book",
    "Clock",
    "Fish",
    "Internet",
    "Cats",
    "Raining",
    "Dogs",
    "Sing",
    "Potato",
    "Tomato",
    "Glass",
    "Alarm",
    "Software",
    "Switch",
    "Running",
    "Shoes",
    "Wallet",
    "Computer",
    "Speaker",
    "Friend",
    "Absolute",
    "Open",
    "Closed",
    "Secure",
    "Park"
]
