// import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Button, Typography } from '@mui/material';
import { useCopyToClipboard } from 'react-use';
import { useEffect } from 'react';

const RenderSecret = ({ secret }: { readonly secret: string }) => {
  // const { t } = useTranslation();
  const [copy, copyToClipboard] = useCopyToClipboard();

  return (
    <div>
      <Typography variant="h4">Decrypted Message</Typography>
      <Typography>This secret might not be viewable again, make sure to save it now!</Typography>
      <Button
        color={copy.error ? 'secondary' : 'primary'}
        onClick={() => copyToClipboard(secret)}
      >
        <FontAwesomeIcon icon={faCopy} /> Copy
      </Button>
      <Typography
        id="pre"
        data-test-id="preformatted-text-secret"
        sx={{
          backgroundColor: '#ecf0f1',
          padding: '15px',
          border: '1px solid #cccccc',
          display: 'block',
          fontSize: '1rem',
          borderRadius: '4px',
          wordWrap: 'break-word',
          wordBreak: 'break-all',
          whiteSpace: 'pre-wrap',
          fontFamily: 'monospace, monospace', // https://github.com/necolas/normalize.css/issues/519#issuecomment-197131966
        }}
      >
        {secret}
      </Typography>
    </div>
  );
};

const Secret = ({
  secret,
  fileName,
}: {
  readonly secret: string;
  readonly fileName?: string;
}) => {
  return <RenderSecret secret={secret} />;
};

export default Secret;
