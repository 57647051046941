import { AppBar, Toolbar, Button, SvgIcon } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import AddovationSvgComponent from './AddovationSvgComponent';

import { useNavigate } from "react-router-dom";

export const Header = () => {
  // const { t } = useTranslation();

  const currentUrl = window.location.toString()
  
  const navigate = useNavigate();

  const handleClick = () => {

    if(currentUrl.charAt(currentUrl.length - 1) === '/')
    {
      // '/' page, reload.
      window.location.reload()
    }
    else
    {
      // dispay secret page, navigate to /
      navigate("/");
    }
  }

  return (
    <AppBar position="static" color="transparent" sx={{ marginBottom: 4 }}>
      <Toolbar sx={{backgroundColor: '#4c8d6a'}}>
        {/* <Link href={home} color="inherit" underline="none"> */}
          {/* <Button onClick={() => history.push('/')}> */}
          <Button
            // onClick={() => window.location.reload()}
            onClick={() => handleClick()}
            // component={Link}
            // href={"/"}
          >
              <SvgIcon component={AddovationSvgComponent}></SvgIcon>
          </Button>
          {/* <Typography variant="h6" component="div">
            Yopass
            <Box
              sx={{
                verticalAlign: 'middle',
                paddingLeft: '5px',
                width: '40px',
                height: '40px',
              }}
              component="img"
              height="40"
              alt=""
              src="yopass.svg"
            />
          </Typography> */}
        {/* </Link> */}
        {/* <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Button
            component={Link}
            href={isOnUploadPage ? home : upload}
            variant="contained"
            color="primary"
          >
            {isOnUploadPage ? t('header.buttonHome') : t('header.buttonUpload')}
          </Button>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};
