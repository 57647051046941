// import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const styles = { paddingTop: 1, paddingBottom: 1 };

export default function ErrorPage(props: { error?: Error }) {
  // const { t } = useTranslation();
  if (!props.error) {
    return null;
  }

  return (
    <div>
      <Typography variant="h4">Secret does not exist</Typography>
      <Typography variant="h5">It might be caused by any of these reasons.</Typography>
      <br />
      <Typography sx={styles} variant="h5">
        Opened before
      </Typography>
      <Typography variant="subtitle1">
        A secret can be restricted to a single download. It might be lost because the sender clicked this link before you viewed it.
        <br />
        The secret might have been compromised and read by someone else. You should contact the sender and request a new secret.
        <Typography sx={styles} variant="h5">
          Broken link
        </Typography>
        The link must match perfectly in order for the decryption to work, it might be missing some magic digits.
        <Typography sx={styles} variant="h5">
          Expired
        </Typography>
        No secret lasts forever. All stored secrets will expire and self destruct automatically. Lifetime varies from one hour up to one week.
      </Typography>
    </div>
  );
};
