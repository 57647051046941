// import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Checkbox,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { wordList } from './data';

interface Props{
    generatedPassword: string;
    setGeneratedPassword: React.Dispatch<React.SetStateAction<string>>;

    generatedPhrase: string;
    setGeneratedPhrase: React.Dispatch<React.SetStateAction<string>>;
    
    generatePhrase?: boolean;
}

export default function PasswordGenerator ({generatedPassword, setGeneratedPassword, generatePhrase, generatedPhrase, setGeneratedPhrase}: Props) {
    const [length, setLength] = useState('15');
    const [includeSpecialCharacters, setIncludeSpecialCharacters] = useState(false);

    const handleLengthChange = (event: SelectChangeEvent) => {
        setLength(event.target.value as string);
    };

    const handleChecked = () => {
        setIncludeSpecialCharacters(!includeSpecialCharacters);
    }

    const generatePassword = () => {

        let charset = ""; 
        let newPassword = ""; 

        if (includeSpecialCharacters){
            charset += "!@#$%^&*()";
        } 

        charset += "0123456789";
        charset += "abcdefghijklmnopqrstuvwxyz";
        charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (let i = 0; i < parseInt(length); i++) { 
            newPassword += charset.charAt( 
                Math.floor(Math.random() * charset.length) 
            ); 
        }

        setGeneratedPassword(newPassword);

        if(generatePhrase)
        {
            setGeneratedPhrase(generateRandomPhrase());
        }

        function generateRandomPhrase()
        {
            var output = '';

            do {
                var number = Math.floor(Math.random() * (wordList.length - 1) + 1);
                var newNumber = Math.floor(Math.random() * (wordList.length - 1) + 1);
                while (newNumber === number) {
                    newNumber = Math.floor(Math.random() * (wordList.length - 1) + 1);
                }
                output += wordList[number];

                if(output.length > 18){
                    output = '';
                }
            } while (output.length < 15);
            
            return output;
        }

    }

    return (
        <>
            <Box display='flex' flexDirection='column'>
                    <Box display='flex' alignItems='center'>
                        <Typography sx={{textAlign: 'left', mr: 1}}>Include special characters</Typography>
                        <Checkbox name='includeSpecialCharacters' sx={{pl: 0}} checked={includeSpecialCharacters} onClick={handleChecked}/>
                    </Box>
                    <Box display='flex' alignItems='center'>
                        <InputLabel id="demo-simple-select-label" sx={{mr: 3.6}}>Password Length</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={length}
                            label="Password length"
                            name='passwordLength'
                            onChange={handleLengthChange}
                            sx={{height: 25}}
                        >
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={40}>40</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={60}>60</MenuItem>
                            <MenuItem value={70}>70</MenuItem>
                        </Select>
                    </Box>

                    <Button variant='contained' sx={{mt: 3, height: 35, mb: 5, backgroundColor: '#4c8d6a' }} onClick={generatePassword}>
                        Generate Password
                    </Button>
            </Box>
        </>
    )
}
