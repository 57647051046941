// import { useTranslation } from 'react-i18next';
import { useForm, UseFormMethods } from 'react-hook-form';
import randomString, {
  encryptMessage,
  isErrorWithMessage,
  postSecret,
} from '../utils/utils';
import Result from '../displaySecret/Result';
import { useState } from 'react';
import Error from '../shared/Error';
import Expiration from '../shared/Expiration';
import {
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import PasswordGenerator from './PasswordGenerator';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCopyToClipboard } from 'react-use';
import { Attribution } from '../shared/Attribution';

export default function CreateSecret () {
  // const { t } = useTranslation();
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [generatedPhrase, setGeneratedPhrase] = useState('');
  const {
    control,
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      generateDecryptionKey: true,
      // secret: '',
      secret: generatedPassword,
    },
  });
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({
    password: '',
    uuid: '',
    customPassword: false,
  });

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.ctrlKey && event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (form: any): Promise<void> => {

    const pw = form.password ? form.password : randomString();
    setLoading(true);
    try {
      const { data, status } = await postSecret({
        // This is where expiration is beeing set
        expiration: parseInt(form.expiration),
        message: await encryptMessage(form.secret, pw),
        // HARD CODE one_time to true
        one_time: true,
        // ORGI Code
        // one_time: form.onetime,
      });

      if (status !== 200) {
        setError('secret', { type: 'submit', message: data.message });
      } else {
        setResult({
          customPassword: form.password ? true : false,
          password: pw,
          uuid: data.message,
        });
      }
    } catch (e) {
      if (isErrorWithMessage(e)) {
        setError('secret', {
          type: 'submit',
          message: e.message,
        });
      }
    }
    setLoading(false);
  };

  const generateDecryptionKey = watch('generateDecryptionKey');

  if (result.uuid) {
    return (
      <Result
        password={result.password}
        uuid={result.uuid}
        prefix="s"
        customPassword={result.customPassword}
      />
    );
  }

  return (
    <>
      <Error
        message={errors.secret?.message}
        onClick={() => clearErrors('secret')}
      />
      <Typography component="h1" variant="h4" align="center">Encrypt message</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" paddingTop={1}>
          <TextField
            inputRef={register({ required: true })}
            multiline={true}
            name="secret"
            margin="dense"
            fullWidth
            label='Secret message'
            rows="4"
            autoFocus={true}
            onKeyDown={onKeyDown}
            placeholder='Message to encrypt locally in your browser'
            inputProps={{ spellCheck: 'false', 'data-gramm': 'false' }}
          />
          <Grid container justifyContent="center" marginTop={2}>
            <PasswordGenerator
              generatedPassword={generatedPassword}
              setGeneratedPassword={setGeneratedPassword}
              generatePhrase={true}

              generatedPhrase={generatedPhrase}
              setGeneratedPhrase={setGeneratedPhrase}
            />
          </Grid>

          <Box sx={{textAlign: 'center'}}>
            {generatedPassword !== '' &&
            <TableContainer>
                <Table>          
                <TableBody sx={{textDecoration: 'none'}}>
                    <Row value={generatedPassword}/>
                    <Row value={generatedPhrase}/>
                </TableBody>
                </Table>
            </TableContainer>}
          </Box>

          <Grid container justifyContent="center" marginTop={5}>
            <Expiration control={control} />
          </Grid>
          <Grid container alignItems="center" direction="column">
            {/* <OneTime register={register} /> */}
            {/* <SpecifyPasswordToggle register={register} /> */}
            {!generateDecryptionKey && (
              <SpecifyPasswordInput register={register} />
            )}
          </Grid>
          <Grid container justifyContent="center">
            <Box p={2} pb={4}>
              <Button
                sx={{mt: 3, width: 280, height: 55, backgroundColor: '#4c8d6a' }}
                onClick={() => handleSubmit(onSubmit)()}
                variant="contained"
                disabled={loading}
              >
                {loading ? (
                  <span>Encrypting message...</span>
                ) : (
                  <span>Encrypt Message</span>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' sx={{}}>
          <Attribution />
        </Grid>
      </Box>
    </>
  );
};

// export const OneTime = (props: { register: UseFormMethods['register'] }) => {
//   const { t } = useTranslation();
//   return (
//     <Grid item justifyContent="center">
//       <FormControlLabel
//         control={
//           <Checkbox
//             id="enable-onetime"
//             name="onetime"
//             inputRef={props.register()}
//             defaultChecked={true}
//             color="primary"
//           />
//         }
//         label={t('create.inputOneTimeLabel') as string}
//       />
//     </Grid>
//   );
// };

export const SpecifyPasswordInput = (props: {
  register: UseFormMethods['register'];
}) => {
  // const { t } = useTranslation();
  return (
    <Grid item justifyContent="center">
      <InputLabel>Custom decryption key</InputLabel>
      <TextField
        fullWidth
        type="text"
        id="password"
        inputRef={props.register()}
        name="password"
        variant="outlined"
        inputProps={{
          autoComplete: 'off',
          spellCheck: 'false',
          'data-gramm': 'false',
        }}
      />
    </Grid>
  );
};

// export const SpecifyPasswordToggle = (props: {
//   register: UseFormMethods['register'];
// }) => {
//   const { t } = useTranslation();
//   return (
//     <FormGroup>
//       <FormControlLabel
//         control={
//           <Checkbox
//             name="generateDecryptionKey"
//             inputRef={props.register()}
//             defaultChecked={true}
//             color="primary"
//           />
//         }
//         label={t('create.inputGenerateLabel') as string}
//       />
//     </FormGroup>
//   );
// };

// export default CreateSecret;

type RowProps = {
//   readonly label: string;
  readonly value: string;
};
// 
// const Row = ({ label, value }: RowProps) => {
const Row = ({ value }: RowProps) => {
  const [copy, copyToClipboard] = useCopyToClipboard();
  return (
    <TableRow key={value}>
      <TableCell>{value}</TableCell>
      <TableCell width="15">
        <Button
          sx={{backgroundColor: '#4c8d6a'}}
          // color={copy.error ? 'secondary' : 'primary'}
          variant="contained"
          onClick={() => {
            copyToClipboard(value);
            value='';
          } }
        >
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </TableCell>

    </TableRow>
  );
};
