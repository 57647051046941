// import { Container, Link, Typography } from '@mui/material';
import { Box, Container, Typography } from '@mui/material';
// import { useTranslation } from 'react-i18next';

export const Attribution = () => {
  // const { t } = useTranslation();

  const objectDate = new Date();
  const footerText = 'Copyright ©' + objectDate.getFullYear() + '. Addovation Sweden AB'; 

  // const translationAttribution = () => {
  //   return (
  //     <Typography variant="body2" color="textSecondary" align="center">
  //       {t('attribution.translatedBy')}{' '}
  //       <Link href={t('attribution.translatorLink')}>
  //         {t('attribution.translatorName')}
  //       </Link>
  //     </Typography>
  //   );
  // };

    return (
    <Box 
      sx={{
        position: {md: 'fixed' },
        bottom: { md: 0},
        width: '100%',
        textAlign: 'center'
      }}>
      <Typography
        margin={4}
        variant="body2"
        color="textSecondary"
        // align="center"
      >
        {footerText}
        {/* {t('attribution.createdBy')}{' '} */}
        {/* <Link href="https://github.com/jhaals/yopass">{footerText}</Link> */}
      </Typography>
        {/* {footerText} */}
      {/* {t('attribution.translatorName') && translationAttribution()} */}
    </Box>
  );
};

    // <Container sx={{width: '100%', position: 'fixed', bottom: 0, backgroundColor: 'red'}}>
    //   <Typography
    //     margin={4}
    //     variant="body2"
    //     color="textSecondary"
    //     align="center"
    //   >
    //     {footerText}
    //     {/* {t('attribution.createdBy')}{' '} */}
    //     {/* <Link href="https://github.com/jhaals/yopass">{footerText}</Link> */}
    //   </Typography>
    //     {/* {footerText} */}
    //   {/* {t('attribution.translatorName') && translationAttribution()} */}
    // </Container>